/* eslint-disable */
import { Table as TableAntd, Button } from "antd";

import { columns } from "./config";
import styles from "./index.less";

const Table = (props) => {
  const { tableData, paginationInfo, setPaginationInfo, setModalInfo } = props;

  const New = () => {
    return (
      <div className={styles.new}>
        <Button
          type="primary"
          onClick={() => {
            setModalInfo({
              title: "新建",
              open: true,
              params: {},
            });
          }}
        >
          新建
        </Button>
      </div>
    );
  };

  // 第一列增加序号
  const indexColumns = {
    title: "序号",
    dataIndex: "index",
    key: "index",
    render: (text, record, index) =>
      (paginationInfo?.page - 1) * paginationInfo?.pageSize + index + 1,
  };

  const finalColumns = [indexColumns, ...columns];

  return (
    <div className={styles.table}>
      <TableAntd
        columns={finalColumns}
        dataSource={tableData}
        locale={{ emptyText: "无数据" }}
        pagination={{
          total: tableData.length,
          current: paginationInfo.page,
          pageSize: paginationInfo.pageSize,
          pageSizeOptions: [10, 20, 50, 100],
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            // console.log("onChange======>", page, pageSize);
            setPaginationInfo({ page, pageSize });
          },
        }}
        title={() => <New />}
      />
    </div>
  );
};

export default Table;
