/* eslint-disable */
import { Button, Form, Input, Row, Col, message } from "antd";

import styles from "./index.less";

const Filters = (props) => {
  const { setQueryParams } = props;

  const idRegex = /^[1-9]\d*$/;
  const ageRegex = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/;
  const telRegex = /^\d{11}$/;

  const onFinish = (values) => {
    const params = {};
    // console.log("点击查询:", values);
    const { id, userName, age, tel } = values;
    if (id) {
      if (idRegex.test(id)) {
        params.id = Number(id);
      } else {
        message.warning("请输入正整数");
        return;
      }
    }
    if (userName) {
      params.userName = userName;
    }
    if (age) {
      if (ageRegex.test(age)) {
        params.age = Number(age);
      } else {
        message.warning("请输入正确的年龄");
        return;
      }
    }
    if (tel) {
      if (telRegex.test(tel)) {
        params.tel = tel;
      } else {
        message.warning("请输入11位手机号");
        return;
      }
    }
    console.log("params======>", params);
    setQueryParams(params);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("查询失败:", errorInfo);
  };
  return (
    <div className={styles.filters}>
      <Form
        name="filters"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row justify="start">
          <Col span={8}>
            <Form.Item label="编号" name="id">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="姓名" name="userName">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="年龄" name="age">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="手机号" name="tel">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} />
          <Col span={8}>
            <Row justify="center">
              <Col span={8}>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Filters;
