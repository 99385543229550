/* eslint-disable */
import { useEffect } from "react";
import { Modal as ModalAntd, Form, Input, Row, Col, message } from "antd";

import styles from "./index.less";

const Modal = (props) => {
  const [form] = Form.useForm();
  const { modalInfo, setModalInfo, delete_m, update_m, insert_m } = props;

  // 点击确定
  const handleOk = () => {
    if (modalInfo.title === "删除") {
      const { id } = modalInfo.params;
      delete_m({ id }).then(() => {
        console.log("删除成功");
        setModalInfo({ ...modalInfo, open: false });
      });
    } else if (modalInfo.title === "编辑") {
      const ageRegex = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/;
      const telRegex = /^\d{11}$/;
      const params = {};
      form
        .validateFields()
        .then((res) => {
          const { id, userName, age, tel } = form.getFieldsValue();
          params.id = Number(id);
          if (userName) {
            params.userName = userName;
          }
          if (age) {
            if (ageRegex.test(age)) {
              params.age = Number(age);
            } else {
              message.warning("请输入正确的年龄");
              return;
            }
          }
          if (tel) {
            if (telRegex.test(tel)) {
              params.tel = tel;
            } else {
              message.warning("请输入11位手机号");
              return;
            }
          }
          update_m(params).then(() => {
            console.log("编辑成功");
            setModalInfo({ ...modalInfo, open: false });
          });
        })
        .catch((error) => {
          console.log("validateFieldserror======>", error);
        });
    } else if (modalInfo.title === "新建") {
      const ageRegex = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/;
      const telRegex = /^\d{11}$/;
      const params = {};
      form
        .validateFields()
        .then(() => {
          const { userName, age, tel } = form.getFieldsValue();
          if (userName) {
            params.userName = userName;
          }
          if (age) {
            if (ageRegex.test(age)) {
              params.age = Number(age);
            } else {
              message.warning("请输入正确的年龄");
              return;
            }
          }
          if (tel) {
            if (telRegex.test(tel)) {
              params.tel = tel;
            } else {
              message.warning("请输入11位手机号");
              return;
            }
          }
          insert_m(params).then(() => {
            console.log("新建成功");
            setModalInfo({ ...modalInfo, open: false });
          });
        })
        .catch((err) => {
          console.log("validateFieldsreserr======>", err);
        });
    }
  };
  // 点击取消
  const handleCancel = () => {
    setModalInfo({ ...modalInfo, open: false });
  };

  useEffect(() => {
    if (modalInfo.title === "编辑" && modalInfo.open === true) {
      const { id, userName, age, tel } = modalInfo.params;
      form.setFieldsValue({
        id,
        userName,
        age,
        tel,
      });
    }
    if (modalInfo.title === "新建" && modalInfo.open === true) {
      form.resetFields();
    }
  }, [modalInfo]);

  return (
    <div>
      <ModalAntd
        open={modalInfo.open}
        title={modalInfo.title}
        onOk={handleOk}
        onCancel={handleCancel}
        getContainer={() => document.getElementById("movieRoleInfo")}
      >
        {modalInfo.title === "删除" && (
          <div>
            确定要删除
            <span style={{ color: "red", fontWeight: "bold" }}>
              {modalInfo?.params?.userName}
            </span>
            的信息吗？
          </div>
        )}
        {(modalInfo.title === "编辑" || modalInfo.title === "新建") && (
          <div className={styles.modal}>
            <Form
              form={form}
              name="modal"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
            >
              <Row justify="start">
                {modalInfo.title === "编辑" && (
                  <Col span={24}>
                    <Form.Item
                      label="编号"
                      name="id"
                      rules={[{ required: true, message: "请输入编号！" }]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item
                    label="姓名"
                    name="userName"
                    rules={[{ required: true, message: "请输入姓名！" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="年龄"
                    name="age"
                    rules={[{ required: true, message: "请输入年龄！" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="手机号"
                    name="tel"
                    rules={[{ required: true, message: "请输入手机号！" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </ModalAntd>
    </div>
  );
};

export default Modal;
