import axios from "axios";

console.log("当前环境为======>", process.env.NODE_ENV);
// console.log("当前环境为======>", process.env);
const basic = process.env.REACT_APP_API_URL;

export const search = (params) => {
  return axios.post(`${basic}/api/search`, {
    data: params,
  });
};

export const deleteFrom = (params) => {
  return axios.post(`${basic}/api/deleteFrom`, {
    data: params,
  });
};

export const update = (params) => {
  return axios.post(`${basic}/api/update`, {
    data: params,
  });
};

export const insert = (params) => {
  return axios.post(`${basic}/api/insert`, {
    data: params,
  });
};
