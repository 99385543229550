import { Space, Button } from "antd";

let setModalInfo;

export const getCommunication = (obj) => {
  setModalInfo = obj.setModalInfo;
};

export const columns = [
  {
    title: "编号",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "姓名",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "年龄",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "手机号",
    dataIndex: "tel",
    key: "tel",
  },
  {
    title: "操作",
    dataIndex: "operate",
    key: "operate",
    render: (text, record) => (
      <Space size="middle">
        <Button
          type="primary"
          onClick={() => {
            setModalInfo({
              title: "编辑",
              open: true,
              params: record,
            });
          }}
        >
          编辑
        </Button>
        <Button
          type="primary"
          danger
          onClick={() => {
            setModalInfo({
              title: "删除",
              open: true,
              params: record,
            });
          }}
        >
          删除
        </Button>
      </Space>
    ),
  },
];
