import zhCN from "antd/es/locale/zh_CN";
import { ConfigProvider } from "antd";
import { Routes, Route } from "react-router-dom";

import MovieRoleInfo from "./components/movieRoleInfo/View";
import Index from "./components/index/View";
import NotFound from "./components/notFound/View";

function App() {
  return (
    <div>
      <ConfigProvider locale={zhCN}>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/MovieRoleInfo" element={<MovieRoleInfo />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;
