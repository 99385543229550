import { Button } from "antd";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <div>
      <h1>这是主页</h1>
      <Link to="/movieRoleInfo">
        <Button>点击跳转到明星信息统计页面</Button>
      </Link>
    </div>
  );
};

export default Index;
