// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__movieRoleInfo___iwfrs {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
}
.index__movieRoleInfo___iwfrs .index__filters___qS4UX {
  width: 80%;
}
.index__movieRoleInfo___iwfrs .index__table___SDVFW {
  width: 80%;
}
.index__movieRoleInfo___iwfrs .index__table___SDVFW .index__new___aLqlY {
  display: flex;
  justify-content: flex-end;
}
.index__movieRoleInfo___iwfrs .index__modal___pT_yn {
  width: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/components/movieRoleInfo/index.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;EACA,sBAAA;AACF;AARA;EAWI,UAAA;AAAJ;AAXA;EAeI,UAAA;AADJ;AAdA;EAmBM,aAAA;EACA,yBAAA;AAFN;AAlBA;EAyBI,UAAA;AAJJ","sourcesContent":[".movieRoleInfo {\n  padding-top: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  flex-direction: column;\n  box-sizing: border-box;\n  // gap: 20px;\n\n  .filters {\n    width: 80%;\n  }\n\n  .table {\n    width: 80%;\n\n    // 新建按钮\n    .new {\n      display: flex;\n      justify-content: flex-end;\n    }\n  }\n\n  .modal {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"movieRoleInfo": `index__movieRoleInfo___iwfrs`,
	"filters": `index__filters___qS4UX`,
	"table": `index__table___SDVFW`,
	"new": `index__new___aLqlY`,
	"modal": `index__modal___pT_yn`
};
export default ___CSS_LOADER_EXPORT___;
