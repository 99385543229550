import React from "react";

const NotFound = () => {
  return (
    <div>
      <h2>404 - 该页面不存在</h2>
    </div>
  );
};

export default NotFound;
