/* eslint-disable */

import { useEffect, useState } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

import Filters from "./Filters";
import Table from "./Table";
import styles from "./index.less";
import { search, deleteFrom, update, insert } from "./servies";
import { getCommunication } from "./config";
import Modal from "./Modal";

const MovieRoleInfo = () => {
  const initialPageInfo = {
    page: 1,
    pageSize: 10,
  };
  const initialModalInfo = {
    title: "",
    open: false,
    params: {},
  };

  // 查询参数
  const [queryParams, setQueryParams] = useState({});
  // 表格数据
  const [tableData, setTableData] = useState([]);
  // 分页信息
  const [paginationInfo, setPaginationInfo] = useState(initialPageInfo);
  // 弹窗信息
  const [modalInfo, setModalInfo] = useState(initialModalInfo);

  useEffect(() => {
    getCommunication({
      setModalInfo,
    });
  }, [queryParams, tableData]);

  // 调查询接口
  const query_m = (params) => {
    search(params).then((res) => {
      // console.log("res======>", res);
      const {
        data: { data },
      } = res || [];
      setTableData(data);
    });
  };

  // 点击删除
  const delete_m = (params) => {
    return deleteFrom(params).then((res) => {
      console.log("res======>", res);
      query_m(queryParams);
    });
  };

  // 点击编辑
  const update_m = (params) => {
    return update(params).then((res) => {
      console.log("res======>", res);
      query_m(queryParams);
    });
  };

  // 点击新建
  const insert_m = (params) => {
    return insert(params).then((res) => {
      console.log("res======>", res);
      query_m(queryParams);
    });
  };

  // 点击查询
  useEffect(() => {
    query_m(queryParams);
  }, [queryParams]);

  return (
    <div className={styles.movieRoleInfo} id="movieRoleInfo">
      <Link to="/" style={{ position: "absolute", left: "0px", top: "0px" }}>
        <Button>返回主页</Button>
      </Link>
      <Filters setQueryParams={setQueryParams} />
      <Table
        tableData={tableData}
        paginationInfo={paginationInfo}
        setPaginationInfo={setPaginationInfo}
        setModalInfo={setModalInfo}
      />
      <Modal
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
        delete_m={delete_m}
        update_m={update_m}
        insert_m={insert_m}
      />
    </div>
  );
};

export default MovieRoleInfo;
